import React, { Component } from "react";
import * as Icon from 'react-bootstrap-icons';
import {
    Card, CardBody, Col, Row, Spinner, Badge, CardHeader, Button, Alert,
    Dropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    UncontrolledTooltip,
    Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import authService from "./api-authorization/AuthorizeService";

export class SearchResult extends Component {
    static displayName = SearchResult.name;

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            tooltip: '',
            description: '',
            rating: null,
            tags: '',
            cast: '',
            posterLink: '',
            poster: {},
            tooltipOpen: false,
            tmdbId: 0,
            type: 'movie',
            modal: false,
            genre: '',
            networks: '',
            status: '',
            seriesInfo: {},
            seasons: [],
            selectedSeasons: [],
            actionsOpen: false,
            isSubscribed: false,
            canChangeSubscription: true
        };

        this.subscribeClick.bind(this);
        this.toggle.bind(this);

        this.key = uuidv4();

        this.controller = new AbortController();
        this.signal = this.controller.signal;

    }

    async componentDidMount() {
        await this.setState({ data: this.props.data });
        this.user = await authService.getUser();
        this.getExtendedDetails();

    }

    componentWillUnmount() {
        this.controller.abort();
    }

    toggle = () => this.setState({ selectedSeasons: [], modal: !this.state.modal });

    toggleActions = () => this.setState({ actionsOpen: !this.state.actionsOpen });

    downloadFile = async (e, t) => {
        t.tmdbId = this.state.tmdbId;
        t.type = this.state.type;

        let user = await authService.getUser();
        let data = {
            name: t.name,
            link: t.downloadLink,
            tmdbId: t.tmdbId,
            mediaType: t.season ? 'tv' : 'movie',
            userId: user.sub
        };
        confirmAlert({
            title: 'Download file?',
            message: `Are you sure you want to download ${t.name}?`,
            buttons: [
                {
                    label: 'Yes!',
                    onClick: async () => {
                        try {
                            var result = await fetch(`api/torrents/download`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(data) // body data type must match "Content-Type" header
                            });
                            if (result) {
                                toast.success('Download successful!');
                            } else {
                                toast.error('Download failed!');
                            }
                        } catch (eror) {
                            toast.error('Download failed!');
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });

    }

    downloadSeason = async (e, season) => {

        let user = await authService.getUser();
        let data = {
            name: this.state.seriesInfo.name,
            tmdbId: this.state.seriesInfo.id,
            season: season,
            userId: user.sub
        };
        confirmAlert({
            title: 'Download Season?',
            message: `Are you sure you want to download ${data.name} Season ${data.season}?`,
            buttons: [
                {
                    label: 'Yes!',
                    onClick: async () => {
                        try {
                            var result = await fetch(`api/torrents/download/season`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(data) // body data type must match "Content-Type" header
                            });
                            if (result) {
                                toast.success('Download successful!');
                            } else {
                                toast.error('Download failed!');
                            }
                        } catch (eror) {
                            toast.error('Download failed!');
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    subscribeClick = async (e, result) => {
        e.preventDefault();
        if (!this.state.canChangeSubscription) {
            return;
        }

        if (this.state.isSubscribed) {
            confirmAlert({
                title: 'Unsubscribe?',
                message: `Are you sure you want to unsubscribe from ${this.state.data.title}?`,
                buttons: [
                    {
                        label: 'Yes!',
                        onClick: async () => {
                            try {
                                const response = await fetch(`/api/tmdb/tv/unsubscribe/${this.state.tmdbId}`, {
                                    method: 'DELETE',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: {}
                                });
                                const data = await response.json();
                                if (data.success) {
                                    toast.success(`You have successfully unsubscribed from ${this.state.data.title}.`);
                                    this.setState({ isSubscribed: false });

                                    if (this.props.subscriptionChanged) {
                                        this.props.subscriptionChanged()
                                    }

                                } else {
                                    toast.error('An error occurred while unsubscribing!');
                                }
                            } catch (eror) {
                                toast.error('An error occurred while unsubscribing!');
                            }
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });

        } else {
            let availableSeasons = [...new Set(result?.torrents.filter(t => !t.isOnPlex).map(t => t.season))];
            await this.setState({ seasons: availableSeasons });

            this.toggle();
        }
    }

    subscribe = async e => {
        e.preventDefault();
        var seasons = this.state.selectedSeasons.map(s => s.replace('S', ''));
        var user = await authService.getUser();
        var url = `/api/tmdb/tv/${this.state.tmdbId}/subscribe?userid=${user.sub}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(seasons)
        });
        const data = await response.json();

        this.setState({ modal: false, isSubscribed: data.success });

        if (data.success) {
            toast.success(`Congrats! You are now subscribed to ${data.series}.`);
        } else {
            if (data.message) {
                toast.error(data.message);
            } else {
                toast.error(`An error occurred while subscribing to ${data.series}.`);
            }
        }

        if (this.props.subscriptionChanged) {
            this.props.subscriptionChanged();
        }
        
    }

    seasonCheckChange = e => {
        let newArray = [...this.state.selectedSeasons];
        if (e.target.checked) {
            newArray = [...this.state.selectedSeasons, e.target.id];
        } else {
            newArray.splice(newArray.indexOf(e.target.id), 1);
        }
        this.setState({ selectedSeasons: newArray });
    }

    getExtendedDetails = async tries => {
        await this.setState({ loading: true });
        tries = tries == null ? 1 : tries;
        try {

            if (tries > 3) {
                return;
            }
            var id = this.props.tmdbId;
            
            if (this.state.data.isTv) {
                let subscription = this.props.subscriptions ? this.props.subscriptions.find(c => c.id === id) : null;
                const seriesResponse = await fetch(`/api/tmdb/tv/${id}`);
                const series = await seriesResponse.json();
                await this.setState({
                    loading: false,
                    type: 'tv',
                    tmdbId: id,
                    description: series.overview,
                    rating: series.rating.toFixed(1),
                    seriesInfo: series,
                    genre: series.genre,
                    networks: series.network,
                    status: series.status,
                    poster: {
                        small: `https://image.tmdb.org/t/p/w154${series.posterPath}`,
                        medium: `https://image.tmdb.org/t/p/w185${series.posterPath}`,
                        large: `https://image.tmdb.org/t/p/w342${series.posterPath}`,
                        xl: `https://image.tmdb.org/t/p/w500${series.posterPath}`
                    },
                    posterLink: `https://image.tmdb.org/t/p/w342${series.posterPath}`,
                    isSubscribed: subscription != null,
                    canChangeSubscription: subscription ? subscription.userId === this.user.sub : true
                });
            } else {
                const movieResponse = await fetch(`/api/tmdb/movie/${id}`);
                const data = await movieResponse.json(); 
                await this.setState({
                    loading: false,
                    ed: data,
                    type: 'movie',
                    tmdbId: data.id,
                    description: data.overview,
                    rating: data.rating.toFixed(1),
                    poster: {
                        small: data.posterSmall,
                        medium: data.posterMedium,
                        large: data.posterLarge,
                        xl: data.posterXLarge
                    },
                    posterLink: data.posterLarge,
                    genre: data.genre,
                    status: data.status
                });
            }
        } catch (error) {
        }
    }

    render() {
        let result = this.state.data;
        let isTv = result?.isTv;
        let dls = result != null ? result.torrents : [];
        let rtImage = this.state.rating > 6.0
            ? 'tomatometer-fresh.svg'
            : this.state.rating === 0 
                ? 'tomatometer-empty.svg'
                : 'tomatometer-rotten.svg';
        let canSubscribe = isTv && this.state.seriesInfo?.inProduction === true;
        let availableSeasons = [...new Set(result?.torrents.filter(t => !t.isOnPlex && t.season).map(t => t.season))];
        let url = `https://www.themoviedb.org/${(isTv ? 'tv' : 'movie')}/${this.state.tmdbId}`
        let nextEpisode = this.state.seriesInfo?.nextEpisodeToAir;
        let intSort = (a, b) => parseInt(a) > parseInt(b) ? 1 : parseInt(a) < parseInt(b) ? -1 : 0;
        return (
            <div>
                <Card className="h-100 mb-3">
                    <Row className="h-100 g-2">
                        <Col>
                        {this.state.loading
                            ?
                            <CardBody className="minh-600 d-flex flex-column justify-content-center">
                                <Row>
                                    <Col className="text-center">
                                        <Spinner type="grow"> </Spinner>
                                    </Col>
                                </Row>
                            </CardBody>
                            :
                            <CardBody className="d-flex flex-column">
                                <div className="d-flex flex-row mb-3">
                                    <div className="w-128 position-relative">
                                        {this.state.poster ?
                                            <img src={this.state.poster.medium} width="100%" alt="No Poster Available" />
                                            : <div className="text-center mt-2 p-2 text-dark fw-bold" >No Poster Available</div>
                                        }
                                    </div>
                                    <div className="ps-3 d-flex flex-fill flex-column">
                                        <h5 className="m-0 d-flex flex-row align-items-center">
                                            <a href={url} className="link-plex text-decoration-none" target="_blank" rel="noreferrer">{result.title}{result.year ? ` (${result.year})` : ''}</a>

                                            {isTv ?
                                            <Dropdown isOpen={this.state.actionsOpen} toggle={this.toggleActions} direction={"start"} className="ms-auto">
                                                <DropdownToggle color="link" className="text-plex px-0"><Icon.ThreeDotsVertical /></DropdownToggle>
                                                <DropdownMenu>
                                                    {canSubscribe
                                                        ?
                                                        <React.Fragment>
                                                            <DropdownItem header>Series</DropdownItem>
                                                                <DropdownItem color="link" disabled={!this.state.canChangeSubscription} onClick={e => this.subscribeClick(e, result)} className={this.state.canChangeSubscription ? "text-plex text-decoration-none" : "text-decoration-none"}><Icon.Check2Circle className="mb-1" /> {this.state.isSubscribed ? "Unsubscribe" : "Subscribe!"}</DropdownItem>

                                                        </React.Fragment>
                                                        : null
                                                    }
                                                    {!result.isOnPlex ?
                                                        <React.Fragment>
                                                            <DropdownItem header>Download</DropdownItem>
                                                                {availableSeasons.sort(intSort).map(season =>
                                                                <DropdownItem key={`season-${season}`} color="link" onClick={e => this.downloadSeason(e, season)} className="text-plex text-decoration-none">
                                                                    Season {season}
                                                                </DropdownItem>
                                                            )}
                                                        </React.Fragment>
                                                        : null}
                                                </DropdownMenu>
                                            </Dropdown>
                                            :
                                            null}
                                        </h5>
                                        <div>
                                            {this.state.genre}
                                        </div>
                                        <div>
                                            {this.state.networks}
                                        </div>
                                        <div className="d-flex flex-row gap-1 align-items-center">
                                            <img src={`images/${rtImage}`} alt="" width="10" height="10" />
                                            {this.state.rating}
                                        </div>
                                        <p className="mb-1 fw-light fst-italic ">
                                            {isTv
                                                ? nextEpisode
                                                    ?
                                                    <span>
                                                        <span>Next episode: <span id="next-episode">S{nextEpisode.season.toString().padStart(2, '0')}E{nextEpisode.episode.toString().padStart(2, '0')} {nextEpisode.name}</span> airs on {new Date(nextEpisode.airDate).toLocaleDateString()}</span>
                                                        {nextEpisode.overview ? <UncontrolledTooltip placement="bottom" target="next-episode">{nextEpisode.overview}</UncontrolledTooltip> : null }
                                                    </span>
                                                    : null
                                                : null
                                            }
                                        </p>
                                        <p className="mb-1"><small>{this.state.description}</small></p>
                                        <div className="mt-auto">
                                            {result.isOnPlex ? <Alert color="secondary" className="text-uppercase text-secondary d-block py-3 mt-auto">{(isTv ? 'All Episodes' : 'Movie')} already on plex</Alert> : null}

                                        </div>
                                    </div>
                                </div>
                                <h6 className="d-flex flex-row gap-2">Downloads <Badge color="secondary">{dls.length}</Badge></h6>
                                {dls.map(t =>
                                    <Card key={`torrent-${t.uniqueId}`} color={t.isPreferred ? "success" : ""} outline disabled={t.isOnPlex} className="mb-2 fs-6 fw-light" >
                                        {t.isPreferred ?
                                            <CardHeader className="py-1 bg-success border-success text-white"><Icon.Check2 /> Preferred Download</CardHeader>
                                            : null
                                        }
                                        <CardBody className="py-2">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="me-3 d-flex flex-column">
                                                    <Badge className="align-self-top" color={t.quality === 'Cam' ? 'danger' : t.resolution === '2160p' ? 'plex' : t.resolution === '720p' ? 'secondary' : 'primary'}>{t.quality === 'Cam' ? 'CAM' : t.resolution === '2160p' ? 'UHD' : t.resolution === '720p' ? 'HD' : 'FHD'}</Badge>
                                                    {t.quality === 'Cam' ? <Icon.HandThumbsDownFill className="align-self-center fs-4 mt-2 text-secondary"
                                                        title="Unless you are jonesing like a crack head that just picked up their welfare check, there is no reason to download this terrible quality copy." /> : null}
                                                </div>
                                                <div className="flex-fill">
                                                    <div className="fw-semibold">{t.name}</div>
                                                    {t.season !== null ? <div className="fw-normal">Season {t.season}, Episode {t.episode}</div> : ''}
                                                    <span className="text-primary">{t.resolution} {t.codec} {t.quality} by {t.group}</span><span className="text-secondary">{t.audio ? ` • ${t.audio} Audio` : ''}</span>

                                                    <div className="text-secondary">{t.size} in {t.files} file(s) </div>
                                                    {t.hardCoded ? <div className="fw-semibold text-danger">Hard coded foreign subtitles</div> : ''}
                                                    {t.files > 6 ? <div className="fw-semibold text-danger">Compressed Archive (Requires manual unpacking)</div> : ''}
                                                    <div className="d-flex flex-row align-items-center">
                                                        <Icon.ArrowUpCircleFill className="text-success pr-1 seeders" />
                                                        &nbsp;
                                                        <span className="text-success pr-2">{t.seeders}</span>
                                                        &nbsp;
                                                        &nbsp;
                                                        <Icon.ArrowDownCircleFill className="text-danger" />
                                                        &nbsp;
                                                        <span className="text-danger pr-1">{t.leechers}</span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-flex flex-column align-items-center">
                                                        {t.isOnPlex ? (isTv ? <Alert color="secondary" className="text-center">Episode Already<br />On Plex</Alert> : null) :
                                                            isTv ?
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle caret color={t.isPreferred ? "success" : "secondary"} className={``}><Icon.CloudDownloadFill /></DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem color="link" data-file={t.name} data-download={t.downloadLink} disabled={t.isOnPlex}
                                                                            className={"text-decoration-none align-middle " + (isTv ? "" : "fs-1 ")
                                                                                + (t.hardCoded || t.files > 6 || t.quality === 'Cam' ? " text-light" : t.isPreferred ? " text-success" : " text-secondary")}
                                                                            onClick={(e) => this.downloadFile(e, t)}>{isTv ? `Download Episode ${t.episode}` : "Download"}</DropdownItem>
                                                                        {!t.isOnPlex && t.isPreferred
                                                                            ?
                                                                            <DropdownItem color="link" className={"text-decoration-none " + (t.isPreferred ? "text-success" : "text-secondary")}
                                                                                onClick={e => this.downloadSeason(e, t.season)}>
                                                                                Download Season {t.season}
                                                                            </DropdownItem> : null}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                                :
                                                                <React.Fragment>
                                                                    {t.hardCoded ? <Badge color="danger" className="ms-1 align-self-middle" title="This version has hardcoded foreign subtitles.">HC</Badge> : ''}
                                                                    <Button color="link" data-file={t.name} data-download={t.downloadLink} disabled={t.isOnPlex}
                                                                        className={`text-decoration-none align-middle fs-1 ${t.hardCoded || t.files > 6 || t.quality === 'Cam' ? " text-light" : t.isPreferred ? " text-success" : " text-secondary"}`}
                                                                        onClick={(e) => this.downloadFile(e, t)}><Icon.CloudDownloadFill /></Button>
                                                                </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                            </CardBody>
                            }
                        </Col>
                    </Row>
                   
                </Card>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Subscribe to {this.state.data?.title}</ModalHeader>
                    <ModalBody>
                        <p>Subscribed shows will automatically download any new episodes as they become available.
                            {this.state.seasons.lengh > 0 ? "Select the seasons below to download any already aired episodes." : ""}</p>
                        {this.state.seasons?.length > 0
                            ?
                            <div>
                                <p>Select any seasons that you would like to download while subscribing to this show.</p>
                                {this.state.seasons.sort(intSort).map(season =>
                                    <FormGroup check key={`S${season}`} className="mt-1">
                                        <Input type="checkbox" id={`S${season}`} onChange={this.seasonCheckChange} name={`season${season}`} />{'  '}
                                        <Label check for={`S${season}`} className="mb-0">Season {season}</Label>
                                    </FormGroup>
                                )}
                            </div>
                            :
                            null
                        }
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="plex" onClick={this.subscribe}>
                            Subscribe!
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}