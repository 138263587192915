import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler,
    NavItem, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    render() {
        return (
          <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-dark border-bottom box-shadow mb-3" dark>
                <NavbarBrand tag={Link} to="/"><img src="/images/nerdyobjects-logo.png" alt="NerdyObjects" /></NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2 border-0" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <LoginMenu onNavLinkClicked={this.toggleNavbar} />
                        </ul>
                    </Collapse>
            </Navbar>
          </header>
        );
    }
}
